import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles"

const useBreakpoints = () => {
  
  const theme = useTheme();
  const smartphone = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const mobile = useMediaQuery(theme.breakpoints.between('sm', 'tablet'));
  const tablet = useMediaQuery(theme.breakpoints.between('tablet', 'md'));
  const laptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  
  return {
    smartphone,
    mobile,
    tablet,
    laptop,
    desktop,
  }
}

export default useBreakpoints