import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { handlee } from "@/utils/fonts";
import { GodfathersProps } from "@/interface/godfathers";
import useBreakpoints from "@/hooks/useBreakpoints";
import Image from "next/image";

const GodfathersItem = (props: GodfathersProps) => {
  const {
    sheAlt,
    sheSrc,
    sheInitials,
    heAlt,
    heSrc,
    heInitials,
    godfathersType,
    godfathersName,
    borderPosition,
  } = props;

  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Stack>
      <Stack direction={"row"} spacing={2} justifyContent={"space-evenly"}>
        <Avatar
          sx={{
            width: 128,
            height: 128,
            border: "8px solid white",
          }}
        >
          <Image
            src={sheSrc}
            alt={sheAlt}
            width={112}
            height={149}
            quality={100}
          />
          {/* {sheInitials} */}
        </Avatar>

        <Avatar
          sx={{
            width: 128,
            height: 128,
            border: "8px solid white",
          }}
        >
          <Image
            src={heSrc}
            alt={heAlt}
            width={112}
            height={149}
            quality={100}
          />
          {/* {heInitials} */}
        </Avatar>
      </Stack>
      <Stack
        sx={{
          borderBottom: "8px ridge var(--color-secondary-2-1)",
          borderLeft:
            borderPosition === "right"
              ? "none"
              : "8px ridge var(--color-secondary-2-1)",
          borderRight:
            borderPosition === "left"
              ? "none"
              : "8px ridge var(--color-secondary-2-1)",
          borderRadius: "8px",
          padding: "12px",
          boxSizing: "border-box",
        }}
      >
        <Typography
          className={handlee.className}
          fontSize={smartphone || mobile ? "4vw" : "1.25vw"}
          justifyContent={"center"}
          textAlign={"center"}
          color={"var(--color-primary-4)"}
        >
          Padrinos de {godfathersType}
        </Typography>
        <Typography
          className={handlee.className}
          fontSize={smartphone || mobile ? "4vw" : "1.25vw"}
          justifyContent={"center"}
          textAlign={"center"}
          fontWeight={"600 !important"}
          color={"var(--color-primary-4)"}
        >
          {godfathersName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default GodfathersItem;
