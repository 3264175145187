import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Image from "next/image";
import { motion } from "framer-motion";
import { Background, Parallax } from "react-parallax";

import { ephesis, handlee } from "@/utils/fonts";
import useBreakpoints from "@/hooks/useBreakpoints";
import GodfathersItem from "@/components/GodfathersItem";

const Godfathers = () => {
  const { smartphone, mobile, tablet } = useBreakpoints();

  const parallaxImage =
    smartphone || mobile || tablet
      ? "/anillo_arena_mobile.webp"
      : "/anillo_desk.webp";

  return (
    <Parallax strength={300}>
      <Background className={"parallaxImageGf"}>
        <Image
          alt="Godfathers parallax Image"
          src={parallaxImage}
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"2vh"}
      >
        <div
          style={{
            borderRadius: "15px",
            display: "flex",
            background: "rgba(161, 140,94, 0.37)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(6.5px)",
            border: "1px solid rgba(161, 140, 94, 0.3)",
            paddingBlock: ".5vw",
            paddingInline: "2vw",
          }}
        >
          <Stack>
            <Typography
              className={ephesis.className}
              fontSize={smartphone || mobile ? "8vw" : "6vw"}
              textAlign={"center"}
              color={"var(--color-primary-4)"}
              lineHeight={1}
            >
              Rosa & Adrián
            </Typography>
            <Typography
              className={handlee.className}
              fontSize={smartphone || mobile ? "4vw" : "1.5vw"}
              textAlign={"center"}
              lineHeight={1.25}
              color={"var(--color-primary-4)"}
            >
              en compañia de nuestros padres
            </Typography>
            <Typography
              className={handlee.className}
              fontSize={smartphone || mobile ? "4vw" : "1.5vw"}
              textAlign={"center"}
              lineHeight={1.25}
              color={"var(--color-primary-4)"}
            >
              nos complace invitarlos a nuestra boda
            </Typography>

            <Grid container spacing={2} mt={"2vw"}>
              <Grid item xs={12} tablet={5}>
                {/* <motion.div layout> */}
                <Stack spacing={2} alignItems={"center"}>
                  <Typography
                    className={ephesis.className}
                    textAlign={"center"}
                    fontSize={smartphone || mobile ? "6vw" : "2vw"}
                    color={"var(--color-primary-4)"}
                  >
                    Padres de la novia
                  </Typography>

                  <motion.div
                    // ref={scope}
                    initial={{ x: -200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    // animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 128,
                        height: 128,
                        border: "8px solid white",
                      }}
                    >
                      <Image
                        src={"/meche.webp"}
                        alt={"mervatar"}
                        width={112}
                        height={149}
                        quality={100}
                      />
                    </Avatar>
                  </motion.div>

                  <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    // animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Typography
                      className={handlee.className}
                      textAlign={"center"}
                      fontSize={smartphone || mobile ? "4vw" : "1.25vw"}
                      color={"var(--color-primary-4)"}
                      fontWeight={"600 !important"}
                    >
                      Mercedes Cordoba
                    </Typography>
                  </motion.div>

                  <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    // animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 128,
                        height: 128,
                        border: "8px solid white",
                      }}
                    >
                      <Image
                        src={"/armando.webp"}
                        alt={"arvatar"}
                        width={112}
                        height={149}
                        quality={100}
                      />
                    </Avatar>
                  </motion.div>

                  <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    //animate={{ x: 0, opacity: 1, scale: 1 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Typography
                      className={handlee.className}
                      textAlign={"center"}
                      fontSize={smartphone || mobile ? "4vw" : "1.25vw"}
                      color={"var(--color-primary-4)"}
                      fontWeight={"600 !important"}
                    >
                      Armando Olvera
                    </Typography>
                  </motion.div>
                </Stack>
                {/* </motion.div> */}
              </Grid>

              <Grid
                item
                xs={12}
                tablet={2}
                sx={{
                  display: smartphone || mobile ? "none" : "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <Image
                  src="./flower_ornament.svg"
                  alt="ornament"
                  height={450}
                  width={75}
                  quality={100}
                  loading={"lazy"}
                />
              </Grid>

              <Grid item xs={12} tablet={5}>
                <Stack spacing={2} alignItems={"center"}>
                  <Typography
                    className={ephesis.className}
                    textAlign={"center"}
                    fontSize={smartphone || mobile ? "6vw" : "2vw"}
                    color={"var(--color-primary-4)"}
                  >
                    Padres del novio
                  </Typography>
                  <motion.div
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    // animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 128,
                        height: 128,
                        border: "8px solid white",
                      }}
                    >
                      <Image
                        src={"/gaby.webp"}
                        alt={"gavatar"}
                        width={112}
                        height={149}
                        quality={100}
                      />
                    </Avatar>
                  </motion.div>
                  <motion.div
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    // animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Typography
                      className={handlee.className}
                      textAlign={"center"}
                      fontSize={smartphone || mobile ? "4vw" : "1.25vw"}
                      color={"var(--color-primary-4)"}
                      fontWeight={"600 !important"}
                    >
                      Gabriela Reyes
                    </Typography>
                  </motion.div>

                  <motion.div
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    // animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 128,
                        height: 128,
                        border: "8px solid white",
                      }}
                    >
                      <Image
                        src={"/george.webp"}
                        alt={"jorvatar"}
                        width={112}
                        height={149}
                        quality={100}
                      />
                    </Avatar>
                  </motion.div>

                  <motion.div
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    // animate={{ x: 0, opacity: 1 }}
                    transition={{
                      duration: 0.5,
                      ease: "backIn",
                    }}
                  >
                    <Typography
                      className={handlee.className}
                      textAlign={"center"}
                      fontSize={smartphone || mobile ? "4vw" : "1.25vw"}
                      color={"var(--color-primary-4)"}
                      fontWeight={"600 !important"}
                    >
                      Jorge Miranda
                    </Typography>
                  </motion.div>
                </Stack>
              </Grid>
            </Grid>
            <Typography
              className={handlee.className}
              textAlign={"center"}
              fontSize={smartphone || mobile ? "4vw" : "1.5vw"}
              my={"2vh"}
              color={"var(--color-primary-4)"}
            >
              También nos acompañan nuestros padrinos
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} tablet={6}>
                <motion.div
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{
                    duration: 1,
                    ease: "backIn",
                  }}
                >
                  <GodfathersItem
                    sheAlt={"Victoria"}
                    sheSrc={"/vicky.webp"}
                    sheInitials={"VC"}
                    heAlt="Anastacio"
                    heSrc={"/tacho.webp"}
                    heInitials={"AC"}
                    godfathersType={"Velación"}
                    godfathersName={"Victoria y Anastacio"}
                    borderPosition={"right"}
                  />
                </motion.div>
              </Grid>

              <Grid item xs={12} tablet={6}>
                <motion.div
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{
                    duration: 1,
                    ease: "backIn",
                  }}
                >
                  <GodfathersItem
                    sheAlt={"Alessia"}
                    sheSrc={"/alessia.webp"}
                    sheInitials={"A"}
                    heAlt="Jair"
                    heSrc={"/jair.webp"}
                    heInitials={"J"}
                    godfathersType={"Lazo"}
                    godfathersName={"Alessia y Jair"}
                    borderPosition={"left"}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12} tablet={6}>
                <motion.div
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{
                    duration: 1,
                    ease: "backIn",
                  }}
                >
                  <GodfathersItem
                    sheAlt={"Patricia"}
                    sheSrc={"/patys.webp"}
                    sheInitials={"PR"}
                    heAlt="Juan"
                    heSrc={"/juan.webp"}
                    heInitials={"JS"}
                    godfathersType={"Arras"}
                    godfathersName={"Patricia y Juan"}
                    borderPosition={"right"}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12} tablet={6}>
                <motion.div
                  initial={{ y: 200, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  // animate={{ x: 0, opacity: 1 }}
                  transition={{
                    duration: 1,
                    ease: "backIn",
                  }}
                >
                  <GodfathersItem
                    sheAlt={"Diana"}
                    sheSrc={"/dianis.webp"}
                    sheInitials={"DR"}
                    heAlt="Armando"
                    heSrc={"/mandito.webp"}
                    heInitials={"AO"}
                    godfathersType={"Anillos"}
                    godfathersName={"Diana y Armando"}
                    borderPosition={"left"}
                  />
                </motion.div>
              </Grid>
            </Grid>
          </Stack>
        </div>
      </Box>
    </Parallax>
  );
};

export default Godfathers;
